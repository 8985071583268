
import "./arschin.scss"

import img__item_1 from "../../asset/arschin/sistem.jpg";
import img__item_2 from "../../asset/arschin/fgis.jpg";
import img__item_3 from "../../asset/arschin/svedinia.jpg";
import img__item_4 from "../../asset/arschin/result.jpg";


function Arschin (){
    return (
        <div className="arschin">
            <h2 className="body__title">Проверка свидетельства о поверке ФГИС «АРШИН»</h2>
            <p>Будьте внимательны: участились случаи мошенничества со сбором средств за фиктивные поверки счётчика неаккредитованными лицами. Напоминаем, что с сентября 2020 года вступил в силу Федеральный закон о единстве измерений (№102-ФЗ), который упразднил юридическую ценность с любых бумажных свидетельств. Фактически, теперь единственным достоверным результатом поверки счётчиков будет запись в электронной базе «АРШИН». Поэтому рекомендуем проверить наличие свидетельства в официальном реестре и доверять лишь аккредитованным компаниям.</p>
            <p>Обращаем внимание, что теперь все результаты поверок учётного оборудования находятся в публичном доступе. Проверить их наличие вы можете самостоятельно. Но учтите, что за несвоевременное проведение поверочных работ возможно начисление тарифа по нормативу, что может повлечь за собой дополнительные расходы.</p>
            <p>Если при поверке счётчиков воды без снятия вам было выдано бумажное свидетельство – уточните у организации, есть ли у них соответствующие полномочия для внесения результатов в электронную базу «АРШИН». А чтобы избежать подобных проблем – заказывайте поверку только у компаний, которые есть в реестре аккредитованных организаций на сайте Росаккредитации. Список этих организаций так же находится в открытом доступе.</p>
            <h4>Как проверить результаты поверки самостоятельно</h4>
            <h5>Это можно сделать удалённо, через интернет.<br />Просто воспользуйтесь нашей инструкцией:</h5>
            <div className="arschin__images">
                <img src={img__item_1} alt="img__item_1" />
                <img src={img__item_2} alt="img__item_1" />
            </div> 
            <ol>
                <li>Зайдите на официальный сайт ФГИС Росстандарта. В меню выбора системы нужно выбрать пункт «Метрология».</li>
                <li>В разделе ФГИС «АРШИН» выбирайте модуль «Поверки».</li>
                <li>Под заголовком «Сведения о результатах» нажмите кнопку «Посмотреть».</li>
                <li>Введите заводской номер счётчика в строке поиска (найти его на корпусе устройства или в документации).</li>
                <li>Проверьте наличие результатов.</li>
            </ol>
            <div className="arschin__info">
                <img src={img__item_3} alt="img__item_3" />
                <img src={img__item_4} alt="img__item_3" />
            </div>
            <p>На сайте Росстандарта вы можете получить всю информацию о прошедшей поверке: наименование организации, дата проведения работ, номер свидетельства или извещения, статус пригодности и многое другое.</p>
            <p>Согласно Приказу №1815 (от 2.07.2015), аккредитованное лицо должно внести данные о поверке счётчика в базу в течение 60 дней, поэтому результаты могут отображаться не сразу. Если по истечению этого периода данных на сайте не будет – обязательно обратитесь в организацию, которая проводила работу. Ведь без отметки во ФГИС «АРШИН» – прибор будет автоматически считаться непроверенным, даже если у вас есть на руках оригинал бумажного свидетельства.</p>
            <h4>Что делать, если данных о поверке счетчика нет в реестре</h4>
            <p>Помните, что вся ответственность за соблюдение легитимности и своевременности проведения поверочных работ лежит на собственнике прибора учёта. И вот частые случаи, почему пользователи не могут найти данные в реестре базы «АРШИН»:
            Проверьте правильность ввода номера счётчика и повторите запрос.
            В случае отсутствия данных – срочно обратитесь к организации, которая выполняла работы. Убедитесь, что она аккредитована.
            Возможно, после последней поверки ещё не прошло 60 календарных дней.
            </p>
            <p>Мы рекомендуем работать исключительно с проверенными аккредитованными организациями, а также выполнять поверку счетчика не позднее, чем за два месяца до окончания межповерочного периода.</p>
            <p>Чтобы уберечь себя от мошенников – приучите себя всегда проверять наличие выбранной метрологической организации в списке аккредитованных лиц на официальном сайте Росаккредитации. И будьте предельно внимательны при введении номера вашего оборудования в базе «АРШИН» – год выпуска указывать не нужно.</p>
        </div>
    
    )
}


export default Arschin;