
import "./modal.scss";
import axios from "axios";
import React, { useState } from "react";

// Картинки
import userApplication from "../../asset/application/user.svg";
import phoneApplication from "../../asset/application/phone.svg";
import counterApplication from "../../asset/application/counterWater.svg";


function Modal ({active, setActive, children}){
    const [messageName, setMessageName] = useState("");
    const [messagePhone, setMessagePhone] = useState("");
    const [messageQuantity, setMessageQuantity] = useState("");
  
   const urlSend = `Заявка с сайта! \nИмя: ${messageName},\nНомер телефона: ${messagePhone},\nКоличество счетчиков: ${messageQuantity}`
   
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.post("https://api.telegram.org/bot6638340620:AAFlpaHxSSnUuZrxJp5omh31hE9O0FITKQc/sendMessage", {
          chat_id: "-1001629929227",
          text: urlSend,
      });
        alert("Уведомление успешно отправлено!");
        setMessageName("");
      } catch (error) {
        console.error(error);
        alert("Ошибка при отправке уведомления!");
      }
    };
    return (
        <div className={active ? "modal active": "modal"} onClick={()=> setActive(false)}>
            <div className="modal__content" onClick={e => e.stopPropagation()}>
                {children}
                <form onSubmit={handleSubmit}>
                    <h2>Вызвать мастера</h2>
                    <div className="form__modal">
                        <img src={userApplication} alt="userApplication" />
                        <input
                            value={messageName} 
                            onChange={(e) => setMessageName(e.target.value)}  
                            type="text" 
                            name="name" 
                            placeholder="Введите ваше имя" required />
                    </div>
                    <div className="form__modal">
                        <img src={phoneApplication} alt="phoneApplication" />
                        <input
                            value={messagePhone}
                            onChange={(e) => setMessagePhone(e.target.value)}  
                            type="text" 
                            name="tel" 
                            placeholder="Введите ваш телефон" required />
                    </div>
                    <div className="form__modal"><img src={counterApplication} alt="counterApplication" />
                            <select value={messageQuantity} onChange={(e) => setMessageQuantity(e.target.value)} required >
                                <option disabled="" selected="">Количество счетчиков</option>
                                <option value="1">1 счетчик</option>
                                <option value="2">2 счетчик</option>
                                <option value="3">3 счетчик</option>
                                <option value="4">4 счетчик</option>
                                <option value="5">5 счетчик</option>
                                <option value="6">6 счетчик</option>
                                <option value="7">7 счетчик</option>
                                <option value="8">8 и более (скидка 20%)</option>
                            </select>
                    </div>
                    <div className="modal__submit"><input type="submit" /></div>
                </form>
            </div>
        </div>
    )
}

export default Modal;