// Главная страница сайта
import { useState } from "react";
import "./home.scss";

// Компоненты
import ServicesItem from "../../components/Services/ServicesItem";
import SwiperReviews from "../../components/SwiperReviews/SwiperReviews";
import SwiperDocuments from "../../components/SwiperDocuments/SwiperDocuments";
import Application from "../../components/Application/Application";
import Permission from "../../components/Permission/Permission";
import Modal from "../../components/Modal/Modal";

// json файл
// import servicesObj from "../../asset/servicesObj.json";
import reviewsObj from "../../asset/reviewsObj.json";
// объект используемый для вывода услуг
import {servicesObj} from "../../asset/servicesObj.js"
import {swiperDocList} from "../../asset/SwiperDocObj.js"


// Используемы картинки
import imgBgPrimary from "../../asset/mainImg/bg-main.gif"
import klientam from "../../asset/mainImg/klientam.png"





function Home() {
  const [modalActive, setModalActive] = useState(false);
  
    return (

      <main>
        <Modal active={modalActive} setActive={setModalActive}></Modal>
        <section className="primary">
            <div className="primary__text">
                <h1>Поверка счётчиков воды в Ульяновске</h1>
                <h2>Поверка домашних и общедомовых приборов учёта воды и тепла!</h2>
                <p>полный пакет документов и регистрация во ФГИС "АРШИН"</p>
                <div className="primary__text__button">
                  <a className="primary__text__button_btn1 button__action" onClick={()=>setModalActive(true)}>Вызвать мастера</a>
                  <a className="primary__text__button_btn2 button__action" href="https://pub.fsa.gov.ru/ral/view/31426/applicant" target="_blank">Проверить на сайте Росаккредитации</a>
                </div>
            </div>
            <div className="primary__images">
                <span></span>
                <img src={imgBgPrimary} alt="photo" />
            </div>
        </section>
        
        <section className="Application">
          <Application/>
        </section>
        <section className="services">
          <h2 className="body__title">Наши услуги</h2>
          <div className="services__grid">
            <ServicesItem servicesObj={servicesObj} setActive={setModalActive}/>
          </div>
        </section>
        <section className="permission">
          <Permission/>
        </section>
        <section className="documents">
            <h2 className="body__title">Документы, получаемые после поверки</h2>
            <p>Каждый клиент по завершению работ получает полный пакет документов, необходимый для передачи в УК, ЕИРЦ, ДЭЗ. Кроме этого, в течение нескольких рабочих дней, данные о поверке выгружаются нашими сотрудниками во ФГИС "Аршин":</p>
            <SwiperDocuments swiperDocList = {swiperDocList}/>
            <a className="documents__button button__action" onClick={()=>setModalActive(true)}>Вызвать мастера</a>
        </section>
        <section className="klientam">
          <h2 className="body__title">Завышенные показания счётчика воды: что делать и куда обращаться?</h2>
          <h3>Иногда показания счётчиков превышают расход воды из-за неисправной работы измерительного прибора или несвоевременной оплаты услуг.</h3>
          <p> Точная работа счётчиков горячей и холодной воды напрямую зависит от качества и напора воды в трубах. Засорение отверстия прибора по учёту воды является основной причиной увеличения давления в трубе, что может привести к изменению показаний, хотя при этом уровень потребления воды не меняется.</p>
          <div className="klientam__images">
            <img src={klientam} alt="klientam" />
          </div>
        </section>
        <section className="reviews">
          <h2 className="body__title">Отзывы о нашей работе</h2>
          <SwiperReviews reviewsObj ={reviewsObj}/>
        </section>
        <section className="about">
          <h2 className="body__title">Поверка или замена счетчиков воды и тепла в Ульяновске</h2>
          <p>
          «АКВАТЕХНИКА» предоставляет услуги по поверке счетчиков воды на дому без их снятия и лабораторному исследованию теплосчетчиков в Ульяновске. Государственная аккредитация и использование сертифицированного оборудования позволяют нам выдавать официальные Свидетельства о поверке в случае успешного прохождения исследования. На основании этого документа Вы можете продолжать использовать свое учетное оборудование в течение следующих 4-6 лет. За счет широкого штата специалистов Вы можете заказать визит метролога на ближайшую дату. Современное оборудование для исследований позволяет нам сократить время их проведения и предлагать доступную цену на поверку счетчиков тепла, горячей и холодной воды.
          </p>
        </section>
        <section className="call-wizard">
          <h2 className="body__title">Когда нужно вызывать мастера для поверки счетчиков</h2>
          <p>
          В соответствии с законодательными нормами, могут учитываться показатели только тех счетчиков воды и тепла, по которым не истек срок межповерочного интервала. В ином случае, расчет расхода ресурсов считается по средним показателям, которые чаще всего оказываются в несколько раз выше реальных. Межповерочный интервал указывает в паспорте на учетное оборудование. Для счетчиков холодной воды он составляет 6 лет, для горячей – 4 года, а для теплосчетчиков – от 4 до 6 лет. Как правило, о необходимости очередной поверки счетчиков уведомляет управляющая компания, но лучше контролировать межповерочный интервал самостоятельно. Кроме этого Вы можете провести внеплановое исследование в случае несоответствия показаний реальному расходу ресурсов, при повреждении корпуса прибора, после длительного простоя или если отсутствует информация о последней поверке.
          </p>
          <h5>Преимущества нашего сервиса</h5>
          <ul>
            <li>Возможность назначения визита специалиста в любое удобное для Вас время, в том числе в выходные или праздничные дни.</li>
            <li>Выполнение поверки счетчиков на дому без демонтажа учетного оборудования за 15-30 минут.</li>
            <li>Возможность оперативной замены водомера в случае выявления недопустимой погрешности или при завершении срока эксплуатации.</li>
            <li>Использование только сертифицированных метрологических установок для исследования водомеров и собственная лаборатория для поверки теплосчетиков.</li>
            <li>Выгодная цена поверки счетчиков без их снятия и скидки при исследовании 4-х и более приборов в рамках одного визита специалиста.</li>
            <li>Вы не платите за исследование, если счетчик не проходит поверку и мастер выполняет его замену (для физических лиц,при условии замены двух и более приборов учёта), или получаете скидку на установку нового прибора (для юридических лиц, при условии замены двух и более приборов учёта).</li>
          </ul>
          <p>
          Обращаясь к нам, Вы можете рассчитывать на быстрое решение проблем с поверкой счетчиков в Ульяновске так как мы ценим Ваше время. За счет использование современного метрологического оборудования исследование водомеров проходит менее чем за полчаса. При этом используется минимальный объем воды. В случае выявления погрешности наш сотрудник может сразу выполнить замену прибора, что позволит Вам сэкономить не только время, но и средства, так как в этом случае поверка выполняется бесплатно.
          </p>
        </section>
        <section className="Application">
          <Application/>
        </section>
      </main>
    );
  }
  
  export default Home;